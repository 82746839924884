<style lang="sass" scoped>
.title
  font-weight: bold
  font-size: 1.5rem
  color: #555
</style>
<template lang="pug">
div.container-property
  .container
    .row.justify-content-center.align-items-center.async(style='height: 100vh' :class='{done:done}')
      .col-xl-5.col-lg-6.col-md-7
        h3.title.py-3.text-center 이메일 주소로 초대 받았습니다.
        .border.shadow(style='border-radius: 1.25rem')
          .d-flex.p-4
            strong 워크스페이스 이름
            //- strong.ml-auto.text-muted(v-b-tooltip :title='property.subdomain') {{property.name}}
            strong.ml-auto.text-muted(:title='property.subdomain') {{property.name}}
          hr.opacity-50.m-0
          .d-flex.p-4
            strong 계정 정보
            strong.ml-auto.text-muted.text-right
              span.d-block {{session.name}}
              span.opacity-50 {{session.email}}
          hr.opacity-50.m-0
          p.mb-0.pt-4.pl-4.opacity-50(style='font-size: 12px; ') 가입을 클릭하시면 해당 워크스페이스에 계정이 추가되고
            br
            | 접속 권한을 받습니다.
          .d-flex.p-4
            div.w-100.mr-2
              a(href='#' @click='accept').btn-block.btn.btn-primary.p-4(style='border-radius: .55rem') 가입
            div.w-100
              router-link(to='/logout').btn.btn-light.text-primary.bg-light-primary.p-4.btn-block(style='border-radius: .55rem') 로그아웃

</template>

<script>

export default {
  name: 'index',
  props: ['property_subdomain'],
  components: {

  },
  computed: {
    session() {
      return this.$store.state.session
    },
  },
  mounted() {
    this.load()
  },
  data() {
    return {
      done: false,
      invited: {},
      property: {},
    }
  },
  methods: {
    async load() {
      try {
        const r = await this.$http.get(`/v2/property/${this.property_subdomain}/me`)
        if (r?.data?.message != 'ok') throw Error(r?.data?.message || '프로필 가져오기 실패')
        if (!r.data.invited || r.data.invited.length === 0) throw Error('초대된 이메일 주소가 아닙니다.')

        this.invited = r.data.invited[0]
        this.property = r.data.property

        this.done = true
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },
    async accept() {
      try {
        const r = await this.$http.post(`/v2/property/${this.property_subdomain}/accept_invite_user`)
        if (r?.data?.message != 'ok') throw Error(r?.data?.message || '가입 실패')

        this.$router.push({
          name: 'layout',
        })
        this.$modal.show('dialog', {
          title: '🥳 알림',
          text: '새로운 워크스페이스에 가입했습니다.',
        })
      } catch (error) {
        this.$modal.show('dialog', {
          title: '알림',
          text: error.message,
        })
      }
    },

  },
}
</script>
